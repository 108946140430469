import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminSidebar from "../components/AdminSidebar";

const Dashboard = () => {
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        // Fetch schedules from backend
        axios.get("http://localhost:5000/schedule-call")
            .then(response => {
                setSchedules(response.data);
            })
            .catch(error => {
                console.error("Error fetching schedules:", error);
            });
    }, []);

    const handleDelete = (id) => {
        axios.delete(`http://localhost:5000/schedule-call/${id}`)
            .then(() => {
                setSchedules(schedules.filter(schedule => schedule.id !== id));
            })
            .catch(error => {
                console.error("Error deleting schedule:", error);
            });
    };

    return (
      <div className="flex">
        <AdminSidebar/>
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Date</th>
                        <th className="py-2 px-4 border-b">Time</th>
                        <th className="py-2 px-4 border-b">Timezone</th>
                        <th className="py-2 px-4 border-b">Email</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {schedules.map((schedule) => (
                        <tr key={schedule.id}>
                            <td className="py-2 px-4 border-b">{schedule.selectedDate}</td>
                            <td className="py-2 px-4 border-b">{schedule.selectedTime}</td>
                            <td className="py-2 px-4 border-b">{schedule.selectedTimeZone}</td>
                            <td className="py-2 px-4 border-b">{schedule.userEmail}</td>
                            <td className="py-2 px-4 border-b">
                                <button
                                    onClick={() => handleDelete(schedule.id)}
                                    className="text-red-600 hover:text-red-800"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        </div>
    );
};

export default Dashboard;
